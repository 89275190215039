
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@font-face {
  font-family: 'calibribold';
  src: url('../static/font/calibri-bold-webfont.eot');
  src: url('../static/font/calibri-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../static/font/calibri-bold-webfont.woff2') format('woff2'),
       url('../static/font/calibri-bold-webfont.woff') format('woff'),
       url('../static/font/calibri-bold-webfont.ttf') format('truetype'),
       url('../static/font/calibri-bold-webfont.svg#calibribold') format('svg');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'calibriregular';
  src: url('../static/font/calibri-regular-webfont.eot');
  src: url('../static/font/calibri-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../static/font/calibri-regular-webfont.woff2') format('woff2'),
       url('../static/font/calibri-regular-webfont.woff') format('woff'),
       url('../static/font/calibri-regular-webfont.ttf') format('truetype'),
       url('../static/font/calibri-regular-webfont.svg#calibriregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'centuryregular';
  src: url('../static/font/century-webfont.eot');
  src: url('../static/font/century-webfont.eot?#iefix') format('embedded-opentype'),
       url('../static/font/century-webfont.woff2') format('woff2'),
       url('../static/font/century-webfont.woff') format('woff'),
       url('../static/font/century-webfont.ttf') format('truetype'),
       url('../static/font/century-webfont.svg#centuryregular') format('svg');
  font-weight: normal;
  font-style: normal;

}
.ar-style .faqBox h2{text-align: right !important;}
.mw1200{max-width: 1200px; margin: 0 auto;}
textarea:focus, input:focus{ outline: none !important;}
body {color: #333B4A;background: #ffffff;font-family: 'Inter', sans-serif;}
body[dir="rtl"]{  font-family: 'Rubik', sans-serif !important;}
.ar-font{font-family: 'Rubik', sans-serif !important;}
.pl-117px{padding-left: 136px;}
input[readonly]:focus{box-shadow: none;border-color: rgb(209 213 219 / var(--tw-border-opacity))}
.infoOverDiv{transition: 0.5s;}
.infoHover:hover .infoOverDiv{opacity: 1;visibility: visible;transform: translateX(0);transition: 0.5s;}
.footerSection hr {border-color: #2B2E33;}
.headMiddle ul a{  border-bottom: 4px solid transparent;border-radius: 8px;}
.headMiddle ul a:hover {border-bottom: 4px solid #997BF1;}
.headMiddle ul a.active{border-bottom: 4px solid #ECFF1C;}
.getVerifyContent{padding: 0px 20px; max-width: 580px;}
.getVerifyContent.finalCongratulationsPage {max-width: 1000px;}
.getVerifyRightColor{background: var(--main-green, #57FFB8);height: calc(100vh - 74px);}
.stepGrapBorder.active{background-color: #5523E7;}
.grayColorText{color:#8C94A3;}
.focus\:ring-cyan-300:focus {--tw-ring-color: none !important;}
.faqBox .border-gray-200 {border: none;}
.faqBox .bg-gray-100{background-color: transparent;}
.faqBox .focus\:ring-gray-200:focus{--tw-ring-color: none;}
.faqBox button{display: block;position: relative;padding-left: 30px; color: #333B4A;border: none !important;padding-top: 0 !important;padding-bottom: 0 !important;margin-bottom: 8px;margin-top: 32px;font-size: 18px;}
.faqBox button svg{position: absolute;left: 0;top: 0px;}
.faqBox .hover\:bg-gray-100:hover{background-color: transparent; color: #5523E7;}
.faqBox {margin-top: 30px;}
.faqBox div{padding: 0 30px;}
.socialApplySection a, .socialApplySection button {border: 1px solid #D1D5DB;}
.treePixelLinkInput input{padding-left: 118px;}
.signUpMain{background: radial-gradient(194.86% 162.45% at 87.75% 102.17%, #575EFF 3.88%, #0B07B1 100%);}
.signUpMain.loginPage {background: radial-gradient(194.86% 162.45% at 87.75% 102.17%, #57FFD7 3.88%, #07B174 100%);}
.headProfile button.group {padding: 0;margin: 0;border: none; background-color: #fff; border-radius: 50px; background-color: transparent !important;}
.headProfile button span{padding: 0;margin: 0;}
.headProfile button span svg{display: none !important;}
.innerHeaderMain {margin-left: 256px;}
.rightContent{margin-left: 256px;}
.bodermain{border: 1px solid #EEF0F5;}
.boxshadowmain{box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);}
.createSiteFooter button:last-child {background-color: #5523E7;}
.createSiteFooter button:hover:last-child {background-color: #211083;}
.mainBtn{box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); border: 1px solid #C5C9D5; height: 44px; line-height: 44px;}
.cart-save-btns-box {margin: 20px -20px -10px -64px;padding-right: 20px;}
.categories-section .microcart button {padding: 0 10px !important;font-size: 15px;}
.categories-section button {font-size: 14px; width: 100%;border: 1px solid #EEF0F5;border-radius: 8px;height: 56px;text-align: left;padding: 0 16px;font-weight: 600;margin: 0 5px;}
.categories-section button:hover{background-color: #F5F6FA;}
.categories-section button.active{background-color: #F5F6FA;}
.tab-modal-body .focus\:ring-4:focus{box-shadow: none !important;}
.tab-modal-body .text-cyan-600{color: #5523E7;}
.tab-modal-body .border-cyan-600{border-color: #5523E7;}
.categories-section{margin: 0 -5px;}
.allCountry-box .bg-cyan-600{background-color: #5523E7;}
.refer-box{border: 1px solid rgba(146, 99, 255, 0.40);background: #F1EBFF;}
p.status-paid {height: 22px;line-height: 22px;background: #EBFFF8; color: #12B881;}
.drop-shadow-md{--tw-drop-shadow: none ;}
.mobile-preview-box{padding: 10px 0;}
.mobile-show-preview {height: 562px;background-color: #fff;width: 263px;margin: 0 auto;border-radius: 36px;padding-bottom: 42px; background-size: cover;
}

.tweet-container_root__0rJLq{min-width: 234px !important ;}
.ar-style .faqBox h2 {text-align: right !important;}

.checkedBlue{display: none;}
.checkClass .checkedBlue{display: block;}
.checkClass .uncheck{display: none;}
.checkClass {
  border: 1px solid #5523e7;
}

.checkClass svg path{stroke: #5523e7;}
.cropThemeImgPop .flex-1.py-2{padding-top: 0; padding-bottom: 0;} 

.mobile-preview-box iframe{min-width: auto !important;}
.mobile-show-preview::-webkit-scrollbar{display: none;}
.rsme-embed.rsme-twitter-embed{width: auto !important;}
.mySiteLeftBarUl li a.bg-project-color-600 svg path{stroke: #5523E7;}
.mySiteShareDrop button {box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);border: 1px solid #C5C9D5;
  height: 40px;font-weight: 600;padding: 0 16px;border-radius: 8px;font-size: 14px;}
.mySiteShareDrop ul{width: 309px;padding: 0;}
.mySiteShareDrop ul li{padding: 0 16px;}
.mySiteShareDrop ul li button{box-shadow: none;border: none;padding: 10px 16px;font-weight: 500;}
.mySiteShareDrop ul li button.dropdownFooter{border: 1px solid #E0E3EB;}
.cloneSectionMain .inputBox{margin-bottom: 12px;padding-right: 33px;}
.cloneSectionMain .inputBox:first-child{padding: 0;}
.mobileNumberDropInput .css-1u9des2-indicatorSeparator{display: none;}
.mobileNumberDropInput .css-1xc3v61-indicatorContainer,.mobileNumberDropInput .css-15lsz6c-indicatorContainer{padding: 0 !important;}
.mobileNumberDropInput .css-1fdsijx-ValueContainer{padding: 2px 0 2px 8px !important;width: 55px;}
.contactCardMain .mobileNumberDropInput .css-1fdsijx-ValueContainer{width: 70px;}
.mobileNumberDropInput .favoriteGameDrop{float: left;}
.mobileNumberDropInput .css-13cymwt-control,.mobileNumberDropInput .css-t3ipsp-control{min-height: 40px !important;border: none !important;box-shadow: none !important;border-radius: 8px !important;}
.mobileNumberDropInput.detailsPageMobileNumber .css-13cymwt-control, .mobileNumberDropInput.detailsPageMobileNumber .css-t3ipsp-control{top: 2px;}
.css-13cymwt-control,.css-t3ipsp-control{min-height: 40px !important;}
.mobileNumberDropInput .css-1nmdiq5-menu{width: 100px !important;}
.mobileNumberDropInput .css-qbdosj-Input input{box-shadow: none !important;border: none !important;}
.favoriteGameDrop input{box-shadow: none !important;border: none !important;}
.css-1dimb5e-singleValue{font-size: 14px;}
.mobileNumberDropInput .css-1dimb5e-singleValue{font-weight: 500;}
.cart-save-btns-box button {display: inline-block;}
.siteName-share-section .switch-button button div{width: 64px;height: 31px;border-radius: 12px;}
.siteName-share-section .switch-button .toggle-bg:after{height: 16px;width: 16px;left: 8px;top: 8px;filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10));border: none;}
.siteName-share-section .after\:translate-x-full::after {transform: translateX(30px) !important;}
.siteName-share-section .switch-button span{top: 5px}
.profile-setting-page button:focus{box-shadow: none;}
.preview:before{background: radial-gradient(82.56% 82.56% at 49.27% 17.44%, #FFF 88.54%, #EAFFF6 100%);}
.connectedLeftMenu li a.active{border-radius: 6px; border: 2px solid  #BCFFE3; background:  #F2FFFA;}
.snapchatPixelBox a{width: 188px;height: 136px;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
border: 1px solid #EEF0F5; display: block;}
.appsRightBox {width: 640px;}
.StepRound:before {content: "";position: absolute;left: 0;right: 0;height: 10px;width: 10px;background-color: #E0E3EB;
top: 0;bottom: 0;margin: auto;border-radius: 50px;}
.StepRound {border: 2px solid #E0E3EB;position: relative;}
.StepMetaText { color: #333B4A !important }
.firstBoxSetUp.active .StepRound{box-shadow: 0px 0px 0px 4px #EBE8FB; border-color: #5523E7;background-color: #fff;}
.firstBoxSetUp.active p {color: #5523E7;}
.firstBoxSetUp.active .StepRound:before{background-color: #5523E7;}
.firstBoxSetUp.active.step-done .StepRound:before{background-color: #fff;}
.firstBoxSetUp span {position: absolute;left: 5px;margin: 0 auto;text-align: center;top: 5px; display: none;}
.firstBoxSetUp.active.step-done span{display: block;}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;}
/* Firefox */
input[type=number] {
-moz-appearance: textfield;}

.planMainBx {border: 1px solid #E0E3EB;margin-top: 50px;border-radius: 28px;}

.planMainBx.mostpopularBox {background-color: #ecff1c;border-radius: 12px 12px 26px 26px;padding: 0 4px 4px;margin-top: 0 !important;border: none;}
.planAndFeaturesBox {background-color: #121314;border-radius: 28px;}
/* new home page */
.makeThreeBoxSection h3{font-size: 32px; text-shadow: -3px 2px 0px #000;     line-height: 48px;}
.makeThreeBoxSection {box-shadow: -12px 12px 0px 0px #ECFF1C;}
.stylize-look{background: #6FE2FA; }
.embedTweets{background: #FFC700;}
.embedYouTube{background: #6C26E9;}
.considerMorSection h2 span{background: linear-gradient(267deg, #6FE2FA 1.43%, #ECFF1C 99.04%);background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.uniqueInputBox input {background-color: #1B1C1F;border: 1px solid #32363D; height: 52px; padding-left: 82px;}
.newfeatureBox {border-radius: 50px;border: 1px solid #9492AB;display: inline-block;padding: 10px 5px;}
h5.newFeatureTxt{color: #6FE2FA; border-bottom: 2px solid #6FE2FA; padding: 6px 16px; display: inline-block;}
.featuresBoxSection {box-shadow: -4px 4px 0px 0px #E5F61B;}
.pixelConnections{border: 4px solid #75DFFC; box-shadow: -4px 4px 0px 0px #75DFFC;}
.advanceAnalyticsBx h3 {line-height: 60px;}
.pixelConnections.advanceAnalyticsBx{box-shadow: -4px 4px 0px 0px #F67F8C; border: 4px solid #F67F8C;}
.pixelConnections.shopifyIntegrationBx{box-shadow: -4px 4px 0px 0px #FFC700; border: 4px solid #FFC700;}
.seoOptimiz {border: 4px solid #5AE443; box-shadow: -4px 4px 0px 0px #5AE443;}
.makeYourLinkSection.SellDirectly .makeThreeBoxSection {box-shadow: -12px 12px 0px 0px #75DFFC; border: 4px solid #75DFFC;}
.makeYourLinkSection.SellDirectly h3{color: #75DFFC;}
.footerSection {border-top: 1px solid #32363D;}
.morBannerSection h2 {line-height: 90px;text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
.allRights{border-top: 1px solid #2B2E33;}
.urlCopyBtn {top: 50%;transform: translateY(-50%);padding: 10px 10px;}
.loginBtn:hover{border: 1px solid #C3BAF4;}
.ar-style span.bannerh2 { display: block;}
.ar-style .my-invoices-section tr td{text-align: right}
.ar-style .my-invoices-section tr{text-align: right}
.siteSettingTab .text-cyan-600{color: #5523E7;}
.siteSettingTab .border-cyan-600{border-color: #5523E7;}
li.borderbottomline {border-bottom: 1px solid #E0E3EB;padding-bottom: 10px;}
.websiteCustomizationSection {border-color: #333 !important;}
.websiteCustomizationSection button{background-color: #1b1c1f; color: #fff; margin-bottom: 0; padding: 20px 0;     font-weight: bold;}
.websiteCustomizationSection button:hover{background-color: transparent; color: #5523E7;}
.focus\:ring-gray-200:focus{    --tw-ring-offset-shadow: none !important;}
.websiteCustomizationSection svg{transform: rotate(0deg);}
.compareMainBox .border-gray-200{border: none;}
.opneDiv button svg{rotate: 180deg;}
.switch-button span {  margin: 0;}
.preview {background-size: cover;}

.headDropBox{z-index: 999 !important;}
.css-qbdosj-Input{width: 50px;}
.mobileNumberDropInput.detailsPageMobileNumber .css-13cymwt-control{background-color: transparent;}
.css-qbdosj-Input input:focus{border: none; box-shadow: none; outline: none;}
.css-166bipr-Input:focus{border: none; box-shadow: none; outline: none;}
.css-1dimb5e-singleValue{ overflow: initial !important;     text-overflow: inherit !important;}
.faqBox button{border: none !important;padding-top: 0 !important;padding-bottom: 0 !important;}
.socialModalMain {height: auto !important;}
.bradingFooterLogo {margin: 0 auto;text-align: center;max-width: 552px;background-color: #ffffff;padding: 2px 13px;border-radius: 60px;}
.bradingFooterLogo svg{width: 70px;}
.social-with-text-box{min-height: 438px}
.DarkModeTheme .bradingFooterLogo{background-color: #000000;}
.DarkModeTheme .bradingFooterLogo svg path{fill: #fff;}
.profiletabBox button.border-cyan-600{ border-color:#5523E7 !important ;}
.profiletabBox button.text-cyan-600{color:#5523E7 !important;}
.signUpMain{height: 100vh;overflow: auto;}
.stcstripBox .stcnumbertxt{display: none;}
.stcstripBox:hover .stcnumbertxt{display: block;}
.stcstripBox:hover .stcTxt{display: none;}
.copyStcNumber{display: none;}
.stcstripBox:hover .copyStcNumber{display: block;}
.ContactInfoPop .rounded-lg{border-radius: 16px !important;}
.crossButtonContact button{padding: 0 !important;}
.crossButtonContact button:hover{background-color: transparent;}
.ar-style{direction: rtl;}
.deailsPageShareDropdown button svg {margin: 0 !important;}
.deailsPageShareDropdown button{display: block; height: 48px;width: 48px;border-radius: 50%;padding: 0;}
.configrationBxTag p {  border: 1px solid #D4F6FD;  background-color: #F1FCFE;  height: 36px;  line-height: 36px; padding: 0 16px; border-radius: 8px;}

.deailsPageShareDropdown li button{height: 44px;width: 100%;border-radius: unset;background-color: transparent;}
.deailsPageShareDropdown li button::before{content: none;}
.mySiteShareDrop ul li button.dontHaveDropSection{height: auto;border: 1px solid var(--dark-teal, #5523E7);background: var(--16-teal, #F2FFFA);border-radius: 12px;}
.mySiteShareDrop ul li .createSiteFreeSection{background-color: #5523E7;border-radius: 8px;color: #ffffff;padding: 0;padding: 8px 12px;}
.mySiteShareDrop ul li .createLearnMoreBtn{width: 40%;padding: 8px 0;text-align: center;}
.firstBoxSetUp.step-done .StepRound:after{background-color: #5523E7;}
.StepRound:after {content: "";position: absolute;height: 1px;background-color: #E3E8EF;width: 250px;top: 10px;left: 22px;}
.divSection{height: 40px;margin-bottom: 15px;border: 1px solid #dddddd;}
.firstBoxSetUp:last-child .StepRound:after {content: none;}
.tableDrop button{background-color: transparent;}
.tableDrop button:hover{background-color: transparent !important;}
.emailVerifySection{background: linear-gradient(to right, #5523E7 58%,#ededed 58%);
height: 100vh;     filter: blur(10px);}
.emailVerfyMainBox {padding: 50px;border-radius: 16px;text-align: center;position: absolute;left: 0;right: 0;top: 50%;transform: translateY(-50%);}
.emailVerfyMainBox img {width: 210px;margin: 0 auto;}
.fantasticLogo{max-width: 70px;}
.deactiveFooter button{padding: 0px 20px;}
.ar-style .rightContent {margin-right: 256px; margin-left: 0;}
.ar-style .innerHeaderMain {margin-right: 256px; margin-left: 0;}
.ar-style .siteName-share-section .switch-button .toggle-bg:after{left: auto; right: 8px;}
.ar-style .siteName-share-section .after\:translate-x-full::after {  transform: translateX(-40px) !important;}
.ar-style .toggle-bg:after{right: 0.125rem ; left: auto; }
.ar-style .switch-button .after\:translate-x-full::after {    transform: translateX(-20px);}
.ar-style .categories-section button{text-align: right;}
.ar-style .mySiteShareDrop button svg.ml-2.h-4.w-4{margin-right: 6px; margin-left: 0;}
.ar-style .cart-save-btns-box {padding-right: 0;margin: 20px -64px -10px -24px;padding-left: 24px;}
.ar-style .popTitle button{margin-left: 0;}
.ar-style .pl-117px { padding-left: 138px;}
.ar-style .directionLtr{direction: ltr;}
.ar-style .StepRound:after{right: 22px;left: auto;}
.ar-style .siteName-share-section .switch-button button div{width: 76px;}
.ar-style .copyUrlBtn{right: auto; left: 20px;}

.rtl .deailsPageShareDropdown.absolute {right: auto;left: 0.625rem;}
.rtl .subscribeAbsolute{left: auto; right: 0.625rem;}

.customNMorLinkBx button.active{ color: #000; background-color: #E0E3EB; box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}
.morLinkSharePop button.ml-auto.inline-flex {
  position: absolute;
  right: 10px;
  top: 17px;
}

.title-bio-box .ql-toolbar {border-radius: 6px 6px 0 0;}
.title-bio-box .ql-editor {height: 80px !important;}
.title-bio-box .ql-container{border-radius: 0 0 6px 6px;}
.ar-style .inputSubcription button{margin-left: 0; margin-right: 10px;}
.ar-style .inputSubcription button:hover{background-color: #211083 !important;}
.otpSubcription {display: block !important;}
.otpSubcription input.otpInput {width: 46px;height: 46px;border-radius: 9px;
border: 2px solid #E0E3EB;box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);}
.otpSubcription input:first-child{margin-left: 0;}
.otpSubcription .otpInputContainer{justify-content: space-between;}
.otpSubcription button{margin-top: 20px; width: 100%; margin-left: 0;}
.tabValueBox {background-color: #F5F6FA;border-radius: 12px;}
/* .copyUrlBtn{position: absolute;right: 20px;top: 50%;transform: translateY(-50%);} */
.notVerifyTag {background-color: #FFF9E5;border-radius: 50px;color: #997700;font-size: 12px;font-weight: 500;
padding: 0px 10px;height: 22px;line-height: 22px;}
span.notVerifyTag.connectedTag {background-color: #EEFCEC;color: #368928;}
.snapchatPixelBox .headDropBox {width: 200px !important;}
.ar-style .productTabBox svg.mr-2.h-5.w-5 {margin-right: 0;margin-left: 8px;}
.peer:checked ~ label .badgeIcon{display: block;}
.tryReaturesSection {background: radial-gradient(93.8% 59.21% at 56.72% 26.28%, #F5F6FA 11.98%, #FAFBFC 34.71%, #FBFCFE 48.96%, #F7F9FC 100%);}
.addProductBtn{box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);}
.selectSiteContentMain {height: 615px;}
.height490 {height: 490px;}
.addProductSmlBtn{color: #754FCC; height: 36px; line-height: 36px ; border: 1px solid #754FCC; }
.productTabBox button.flex.items-center.justify-center{width: 50%;}
.productTabBox .text-cyan-600{color: #5523E7 !important;}
.productTabBox .border-cyan-600{ border-color: #5523E7}
.productTabBox [type='checkbox']:checked ~ label{ border: 1px solid #5523E7  ;}
.leyoutRightSelectionBox [type='checkbox'], [type='radio']{color: #333B4A;}
.leyoutRightSelectionBox [type='checkbox']:focus, .leyoutRightSelectionBox [type='radio']:focus{    --tw-ring-color: #333B4A;}
.productTabBox .styleTabThree button.flex.items-center.justify-center {width: auto !important;}
.productTabBox .bg-gray-100{background-color: transparent;}
.productTwoTab button.flex.items-center.justify-center{width: 50%;}
.productTwoTab .border-cyan-600{border-color: #5523E7; color: #5523E7;}
.leyoutRightSelectionBox [type='radio']:checked ~ label{ border: 1px solid #333B4A  ;}
.layoutLeftPreviewBox {width: 260px;height: 260px;}
.productListBoxSection {overflow: auto;height: 215px;}
.productListBoxSection.gridBoxList{height: 210px;}
.preview .preview-section .productListBoxSection.gridBoxList{height: 345px;}
.mobile-preview-box .carouselProductBox{height: 200px;}
.mobile-preview-box .swiper{z-index: 0;}
.DarkModeTheme .previewShopAllBtn{background-color: #ffffff;color: #000000;}
.DarkModeTheme .licenseBckBtn svg path{fill: #fff; stroke: #fff;}
.previewShopAllBtn{border: 1px solid #D0D5DD;background: #1D5E69;color: #fff;}
.productListContentMain{padding-left: 77px;border: 1px solid #FAFBFC;}
.DarkModeTheme .productListContentMain{padding-left: 77px;border-color: #151515;}
.gridProductSectionMain{border: 1px solid #FAFBFC;}
.DarkModeTheme .gridProductSectionMain{border-color: #151515;}
/* .rtl {font-family: 'Rubik', sans-serif !important;} */
.rtl .left-4{left: auto; right:1rem ;}
.rtl .right-2\.5 {left: 0.625rem; right: auto;}
.rtl .right-0 {right: auto; left: 0;}
.rtl .mySiteShareDrop {direction: rtl;text-align: right;}
.rtl svg.mr-3{margin-right: 0; margin-left: 0.75rem;}
.rtl .dropRTL span.mr-2{margin-left: 8px; margin-right: 0;}
.rtl .mySiteShareDrop ul li button.dontHaveDropSection{text-align: right;}
.productListBoxSection::-webkit-scrollbar{display: none;}
.mobile-show-preview.overflow-auto::-webkit-scrollbar{display: none !important;}
.mobile-show-preview.overflow-auto {-ms-overflow-style: none;scrollbar-width: none;}
.mode-box.active img{border: 3px solid #5523E7 ; border-radius: 8px;}
.mode-box.active h4{color: #5523E7 ; font-weight: 500;}
.BackgroundBox{ width: 90px; height: 124px; border: 2px solid #fff;}
.bckUploadBx{width: 184px; margin-right: 4px; }
.bckUploadBx label{height: 118px;}
.BackgroundBox:hover{border: 2px solid #EEF0F5;}
.productTabBox{gap: 0 !important}
.stylePageSection div[role="tabpanel"]{border: 1px solid #E0E3EB;border-top: none;padding:6px 16px;border-radius: 0 0 8px 8px;}
.styleTabBox .productTabBox .productTabBox{border: none;}
.styleTabBox .productTabBox button{border: 1px solid #E0E3EB;border-radius: 8px 8px 0 0;}
.styleTabBox .productTabBox button[aria-selected="false"]{border-top: none;border-right: none;border-left: none;}
.styleTabBox .productTabBox button[aria-selected="true"]{border-bottom: none;border-color: #E0E3EB;}

.productTabBox .styleTabThree button[aria-selected="true"]{ border: none;  border-bottom: 2px solid #5523E7 !important;}

.styleTabThree div[role="tabpanel"]{border: none; padding: 0;}
.styleTabThree.px-4{padding: 0;}

.BackgroundBox.active{border: 2px solid #5523E7;}
.ar-style .title-bio-box .ql-editor{text-align: right;}
.ar-style .ql-toolbar.ql-snow{text-align: left;}

.ar-style .subscribersStripTable th{text-align: right;}
.ar-style .subscribersStripTable td{text-align: right;}

.buttonShapeBox button:hover{background-color: #F5F6FA;}
.buttonShapeBox button.active{border: 2px solid #5523E7 !important;}
.Shadowfeather{box-shadow: 0px 0.875px 1.749px 0px rgba(16, 24, 40, 0.05);}
.shadowHighlight{box-shadow: 0px 0.875px 1.749px 0px rgba(16, 24, 40, 0.24);}
.shadowContrast{box-shadow: 0px 0.875px 1.749px 0px rgba(16, 24, 40, 0.64);}
.shadow3D{box-shadow: 2px 2px 0px 1px #2B2E33;}

.fillBlackColor{background-color: #2B2E33; color: #fff;}
.fillStrokeBox{background-color: transparent; color: #333B4A; box-shadow: 0px 0.875px 1.749px 0px rgba(16, 24, 40, 0.05);}

.fontRoboto{font-family: 'Roboto' !important;}
.fontComic {font-family: 'Comic Neue', cursive;}
.fontArial{font-family: 'Arial' !important;}
.fontCalibri{font-family: 'calibriregular' !important;}
.fontCentury{font-family: 'centuryregular';}

.rtl-mobile img.absolute{left: auto !important; right: 0.75rem !important;}
.rtl .arabicFont{font-family: 'Rubik', sans-serif !important;}

.en-mobile img.absolute.left-3{left:0.5rem !important; right: auto !important;}
.mapRTLView {direction: ltr !important;}
.rtl-mobile p, .rtl-mobile h2, .rtl-mobile .social-with-text-box a{font-family: 'Rubik', sans-serif !important;}
body.rtl {direction: rtl;}
body.rtl .contactPrevirewTitle{padding-right:2.5rem; padding-left: 0;}
body.rtl .contactPrevirewTitle .absolute.left-0{left: auto; right: 0;}
body.rtl .crossButtonContact .ml-auto { margin-left: 0;  margin-right: auto;}
.websiteFeaturesBox p img{margin: 0 auto; opacity: 0.6;}
.boxFreePlan.active {  background-color: #F9FFBB; border-color: #F4FF77; border-bottom: none; color: #000;}
.boxFreePlan{background-color: #1B1C1F;  border-radius: 12px 12px 0 0;}
.boxFreePlan button {background-color: #C3BAF4; color: #371ADB;}
.boxFreePlan.active button{background-color: #000; color: #fff;}
.websiteFeaturesBox.active{background-color: #F9FFBB; border: 2px solid #F4FF77; border-top: none; border-bottom: none; }
.shopifySetUpModal .max-w-2xl{max-width: 500px; padding: 0;}
.popTitle button svg{ stroke-width: 2.5px;}
.popTitle button{color: #6F7582;}
.compareMainBox {background-color: #1B1C1F; border: 1px solid #32363D;}

.relative.rounded-lg.bg-white.shadow {border-radius: 16px;}  
.shopifySetUpModal .max-h-\[90vh\] {max-height: none;height: 550px;}
.shopifySetUpModal ::marker {font-size: 12px;}

.bottamLogoStrip {
  position: absolute;
  width: 84px;
  bottom: 20px;
  background: #fff;
  margin: 0 auto;
  left: 0;
  right: 0;
  border-radius: 50px;
  padding: 3px 0;
  z-index: 3;
}

/* darkModeCss */
.DarkModeTheme {background-color: #000; color: #F5F8FF;}
.DarkModeTheme .border-gray-200{border-color: #32363D;}
.DarkModeTheme .treeBandingLogoSvg path{fill: #fff;}
.DarkModeTheme .bottamLogoStrip{background-color: #000;}
.DarkModeTheme .logo-upload-box{border: 1px solid #fff;}

/* .DarkModeTheme .deailsPageShareDropdown button{background-color: #252629;} */
/* darkModeCss */

.DarkGraniteTheme {background: linear-gradient(180deg, #1F2124 0%, #121314 100%); color: #F5F8FF; }
.DarkGraniteTheme .border-gray-200{border-color: #32363D;}
.DarkGraniteTheme .treeBandingLogoSvg path{fill: #fff;}
.DarkGraniteTheme .bottamLogoStrip{background-color: #000;}
/* previewPage DarkMode */

.websiteCustomizationSection svg {transform: rotate(0deg);}

.rc-slider-track, .rc-slider-tracks{background-color: #5523e7 !important;}
.rc-slider-handle{background-color: #fff !important; border-color: #5523e7 !important;}

.planNBillingNewPage{max-width: 1200px;}

.mostpopularBox .planBuyBtn {background-color: #ecff1c;border-color: #ecff1c;color: #121314;}
.mostPopularbx{background-color: #5523e7;display: inline-block;color: #fff;border-radius: 20px;padding: 4px 14px;}
.mothooqlicencebX h2{background: var(--asa, linear-gradient(150deg, #00BABD 18.5%, #01B9BD 19.24%, #4274A8 34.83%, #6A499B 45.97%, #7A3996 51.91%, #8A4090 57.1%, #B55281 68.98%, #F16B6B 83.09%));
background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.addProductBtn.mothooqlicencebX{border: 2px solid #AEA8DD;}
.rsme-embed.rsme-youtube-embed{border-radius: 6px;}
.mothooqPreviewBx{border-radius: 16px;border: 2px solid #AEA8DD; display: inline-block;}
.ar-style svg.inline-block.mr-1\.5{margin-left: 4px; margin-right: 0;}
.ar-style .licenseBckBtn {transform: rotate(180deg);margin-right: 0.375rem; margin-left: 0;}

.websiteFeaturesBox.active p{color: #000000;}


@media (max-width:1279px) {
.width88{width: 88%;}
.container {max-width: 100%;}
}
@media (max-width:1200px) {
  .mw1200{padding: 0 16px;}

}
@media (max-width:991px) {
.mobileNumberDropInput .css-1fdsijx-ValueContainer{width: 66px;}
.snapchatPixelBox a{width: auto;}
.snapchatPixelBox {width: 33%;}
.appsRightBox{width: auto; margin: 0 -15px;}
.morBannerSection h2 {
  line-height: 60px;}
}
@media (max-width:767px) {
.headLeftBox ul a{border: none !important;}
.rightContent,.innerHeaderMain {margin-left: 0px;}
.faqBox div {padding: 0;}
.StepRound:after{content: none;}
.ar-style .rightContent, .ar-style .innerHeaderMain{    margin-right: 0px;}
.compareMainBox {overflow: auto; overflow-y: hidden;}
.CustomizationBoxMain.flex {display: block !important;white-space: nowrap;}
.planCompareBox.flex {display: block !important;white-space: nowrap;}
.boxFreePlan{width: 200px;display: inline-block;text-align: center;margin-right: -3px;}
.websiteFeaturesBox.font-medium.text-sm.w-1\/4 {width: 200px;display: inline-block;text-align: center;margin-right: -3px;}
p.h-10.flex.items-center {width: 240px;white-space: break-spaces;text-align: left;}
.featuresBoxSection{box-shadow: none;}
.makeThreeBoxSection h3 {font-size: 26px;line-height: 36px;}
.advanceAnalyticsBx h3 {line-height: normal;}
.headMiddle ul a:hover { border-bottom: none; border-bottom: 0; color: #ecff1c;}
.headMiddle ul a{ border-bottom: none; border-bottom: 0;}

.addALinkModal div[role="dialog"]{padding: 0;}
.addALinkModal .relative.rounded-lg.bg-white.shadow{border-radius: 0;max-height: 100%;height: 100%;}
.addALinkModal .popTitle{padding-bottom: 0;}
.addALinkModal div[role="tabpanel"]{height: calc(100vh - 204px );overflow: auto;margin-right: -20px;padding-right: 20px;padding-bottom: 92px;}
.selectedSocialBox{position: absolute;bottom: 0;left: 26px;background-color: #ffffff;right: 26px;padding-top: 10px;}
.tab-modal-body{position: relative;}
.addALinkModal .createSiteFooter{padding-top: 3px;}
.addALinkModal .added-scoail-btn-box{white-space: nowrap;overflow: auto;}

} 

@media (max-width:640px) {
.cart-save-btns-box {  margin: 20px -16px -10px -14px;}
.ar-style  .cart-save-btns-box {  margin: 20px -16px -10px -14px;}
.addProductBtn {width: 48%;}
.card-title-img-box h2 {white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 100px;}
.editDltBtn {margin-right: 10px;padding-right: 10px;}
.switchBxRow h3{width: 100%;}
.switchBxRow{position: relative; display: block;}
.switchBxRow .switch-button {position: absolute;right: 0;text-align: right;top: 50%;display: block;     transform: translateY(-50%);}
.siteSettingTab{display: block; white-space: nowrap; overflow: auto; overflow-y: hidden;}
.siteSettingTab button{display: inline-block;}


}
@media (max-width:576px) {
.mobileNumberDropInput .css-1fdsijx-ValueContainer{width: 66px;}
.socialModalMain div[role="tablist"]{display: block;white-space: nowrap;overflow: auto;text-align: left;}
.socialModalMain div[role="tablist"] button{display: inline-block;}
.midSectionLogin.p-24 {padding: 40px 20px 20px;}
.snapchatPixelBox {width: 50%;}
.buttonShapeBox button.w-40 { width: auto; padding: 0.5rem 12px;}
.buttonShapeBox button img{display: none;}
.productTabBox .styleTabThree button.flex.items-center.justify-center svg{display: none;}

.shareQrCode #react-qrcode-logo {width: 100px !important; height: 100px !important  ;}
.sharetxtlabel{display: none;}
.mySiteShareDrop svg {margin-right: 0;}
.newfeatureBox{border: none;}
.newFeatureBtn {display: block;font-size: 13px;padding: 7px 3px;margin: 0 auto;width: 100px;margin-bottom: 8px;}
.newfeatureBox:last-child {  font-size: 14px;     margin-top: 20px;}




}

.analyIntegratPixelDrop button.group {width: 30px;}
.analyIntegratPixelDrop button.group span{padding: 0;}
/* .analyIntegratPixelDrop div[data-testid="flowbite-dropdown"]{width: 210px;} */


.otpInputContainer {display: flex;}

.otpInput {width: 2rem;height: 2rem;font-size: 1.5rem;text-align: center;margin: 0 0.5rem;}

/* width */
::-webkit-scrollbar {width: 10px;}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; border-radius: 25px
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; border-radius: 25px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}